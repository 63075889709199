var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{staticClass:"inset-y-0 w-64 max-h-screen border-r border-bordergray text-textgray",class:{
    'hidden': !_vm.isSidebarOpen
  }},[_c('div',{staticClass:"grid grid-flow-col justify-between items-center text-textgray p-2 overflow-hidden mb-0 pb-0"},[_c('img',{staticClass:"w-34 h-10",attrs:{"src":require("../../assets/tasq_white_logo.png")}}),_c('span',{staticClass:"material-icons cursor-pointer",class:{
        'hidden': _vm.$route.name === 'Map'
      },on:{"click":_vm.toggleSidebarMenu}},[_vm._v(" more_vert ")])]),_c('p',{staticStyle:{"float":"left","font-weight":"400","clear":"both","color":"white","font-size":"14px","opacity":"0.65","margin-left":"20px","margin-top":"2px","padding-top":"0px"}},[_vm._v(" "+_vm._s(_vm.operatorName)+" ")]),_c('nav',{staticClass:"grid overflow-hidden hover:overflow-y-auto clear-both mt-20",staticStyle:{"font-size":"15px"}},[_c('ul',_vm._l((_vm.links),function(link){return _c('li',{key:link.to},[_c('div',{staticClass:"grid grid-cols-5 items-center px-3 py-5 cursor-pointer",class:{
            'bg-cyan text-white': _vm.isActiveLink(link),
            'hover:bg-hoverLightHighlight hover:text-white': _vm.isHovering && !_vm.isActiveLink(link)
          },on:{"mouseover":function($event){_vm.isHovering = true},"mouseout":function($event){_vm.isHovering = false},"click":function($event){return _vm.handleSidebarItemClick(link)}}},[_c('img',{class:_vm.isActiveLink(link) ? 'opacity-100' : 'opacity-80',staticStyle:{"width":"25px","margin-left":"8px"},attrs:{"src":require('../../assets/sidebar/' + link.imgName)}}),_c('span',{staticClass:"grid grid-cols-4"},[_vm._v(_vm._s(link.name))])]),(_vm.expandedLink.to === link.to || _vm.isActiveLink(link))?_c('div',{staticClass:"grid-row sidebar-item__children"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"grid gap-y-4 text-textgray",class:link.children.length > 0 ? 'p-5' : 'p-0',staticStyle:{"background":"rgba(0,0,0,0.2)"}},_vm._l((link.children),function(child,idx){return _c('router-link',{key:child.to,staticClass:"contents child-item cursor-pointer",attrs:{"to":{
                  name: child.to,
                }}},[_c('div',{staticClass:"child-item__line-wrapper h-full col-start-1 col-end-2 mx-auto relative"},[_c('div',{staticClass:"h-full w-6 flex items-center justify-center",class:{
                      'hidden': idx === link.children.length - 1,
                    }},[_c('div',{staticClass:"child-item__line absolute h-full w-0.5 bg-white pointer-events-none"})]),_c('div',{staticClass:"child-item__dot w-1 h-1 absolute top-1/2 -mt-3 rounded-full bg-transparent",class:{
                      'bg-white': child.to === _vm.$route.name
                    }})]),_c('div',{staticClass:"col-start-3 col-end-10",class:{
                    'text-white': child.to === _vm.$route.name
                  }},[_vm._v(" "+_vm._s(child.name)+" ")])])}),1)])]):_vm._e()])}),0)]),_c('div',{staticClass:"absolute bottom-2 w-64 hover:bg-hoverLightHighlight"},[_c('div',{staticClass:"p-4 cursor-pointer",on:{"click":function($event){return _vm.handleSidebarItemClick(_vm.userSettingsPageLink)}}},[_c('div',{staticStyle:{"position":"relative","float":"left"}},[_c('div',{staticClass:"uppercase bg-purple-300 rounded-full h-12 w-12 float-right text-white text-xl text-center",staticStyle:{"padding-top":"10px","margin-bottom":"10px"}},[_vm._v(" "+_vm._s(_vm.userInitals)+" ")])]),_c('div',{staticStyle:{"position":"relative","float":"left","margin-left":"10px","margin-top":"-2px"}},[_c('p',{staticClass:"text-md text-white opacity-80"},[_vm._v(" "+_vm._s(_vm.currentName)+" ")]),_c('p',{staticClass:"text-sm text-white opacity-50"},[_vm._v(" Engineer ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }