











































































































































import { Component, Vue } from 'vue-property-decorator';
import { getNameByEmail } from '@/utils/users';
import accountModule from '@/store/modules/accountModule';
import { getConfigEnv, isProd } from '@/utils/helpers';

@Component({
  components: {},
})
export default class AppSidebar extends Vue {
  expandedLink: any = {};

	isHovering = false

	userInitial = ''

  userSettingsPageLink = {
    name: 'UserSettings',
    to: 'UserSettings',
  }

  links = [
    {
      name: 'Operations',
      to: 'Operations',
      icon: 'speed',
	  imgName: 'speedometer_white.png',
	  children: [],
    },
    {
      name: 'Map',
      to: 'Map',
      icon: 'explore',
	  imgName: 'mapview_white_icon.png',
	  children: [],
    },
    {
      name: 'Tasqs',
      to: 'Tasqs',
      icon: 'task',
	  imgName: 'my_tasqs_icon.png',
      children: [
        {
          name: 'Overview',
          to: 'Tasqs',
        },
        {
          name: 'Kanban',
          to: 'TasqsKanban',
        },
        // {
        //   name: 'Reports',
        //   to: 'Reports',
        // },
      ],
    },
    {
      name: 'Workflow',
      to: 'Workflow',
      icon: 'shortcut',
	  imgName: 'workflow_white_icon.png',
	  children: [],
    },
    {
      name: 'Schedule',
      to: 'Workspaces',
      icon: 'edit_calendar',
	  imgName: 'calendar_white_icon.jpg',
	  children: [],
    },
  ];

  isSidebarOpen = true;

  get userInitals() {

	  return getNameByEmail(accountModule.user.firstName.charAt(0) + accountModule.user.lastName.charAt(0));
  }

  get currentName() {
    return getNameByEmail(accountModule.user.email);
  }

  get operatorName() {
	  return getConfigEnv('OPERATOR_LOWERCASED')
  }

  handleSidebarItemClick(link) {
    if (this.expandedLink && link.to === this.expandedLink.to) {
      this.$router.push({
        name: link.to,
      });
      return;
    }
    if (link.children?.length) {
      this.expandedLink = link;
      this.$router.push(link.children[0].to);
      return;
    }
    this.$router.push({
      name: link.to,
    });
  }

  isActiveLink(link) {
    return link.to === this.$route.name
      || this.$route.meta.parent === link.name;
  }

  toggleSidebarMenu() {
    this.isSidebarOpen = !this.isSidebarOpen;
    if (!this.isSidebarOpen) {
      this.$emit('sidebarClose');
    }
  }
}
